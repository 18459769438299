import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog";
import NotificationsFlyoutBox from "components/engagement/notifications/NotificationsFlyoutBox";
import NotificationsFlyoutTrigger from "components/engagement/notifications/NotificationsFlyoutTrigger";
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from "components/ui/Dialog";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/Popover";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

interface INotificationsFlyoutChildrenState {
  onCloseFlyout: () => void;
}

interface INotificationsFlyoutProps {
  disabled: boolean;
  unreadUnseenCount: number;
  markAllAsRead: () => void;
  children: (state: INotificationsFlyoutChildrenState) => ReactNode;
  onOpen?: () => void;
}

export default function NotificationsFlyout({
  disabled,
  unreadUnseenCount,
  markAllAsRead,
  children,
  onOpen,
}: INotificationsFlyoutProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if ((isPopoverOpen || isDialogOpen) && onOpen && typeof onOpen === "function") {
      onOpen();
    }
  }, [isPopoverOpen, isDialogOpen, onOpen]);

  return (
    <>
      <Popover open={isPopoverOpen} onOpenChange={(open) => setIsPopoverOpen(open)}>
        <PopoverTrigger disabled={disabled} className="hidden lg:flex">
          <NotificationsFlyoutTrigger
            unreadUnseenCount={unreadUnseenCount}
            disabled={disabled}
            isOpen={isPopoverOpen}
          />
        </PopoverTrigger>

        <PopoverContent className="overflow-hidden rounded-2xl dark:border-slate-900 dark:shadow-lg">
          <NotificationsFlyoutBox unreadUnseenCount={unreadUnseenCount} markAllAsRead={markAllAsRead}>
            {children({
              onCloseFlyout: () => setIsPopoverOpen(false),
            })}
          </NotificationsFlyoutBox>
        </PopoverContent>
      </Popover>

      <Dialog open={isDialogOpen} onOpenChange={(open) => setIsDialogOpen(open)}>
        <DialogTrigger disabled={disabled} className="flex lg:hidden">
          <NotificationsFlyoutTrigger unreadUnseenCount={unreadUnseenCount} disabled={disabled} isOpen={isDialogOpen} />
        </DialogTrigger>

        <DialogContent className="p-0" hideCloseButton={true}>
          <DialogHeader className="hidden">
            <DialogTitle>
              <FormattedMessage defaultMessage="Notificações" id="l6gpWH" />
            </DialogTitle>
            <DialogDescription>
              <FormattedMessage defaultMessage="Lista de notificações" id="ayLNw+" />
            </DialogDescription>
          </DialogHeader>

          <NotificationsFlyoutBox
            unreadUnseenCount={unreadUnseenCount}
            markAllAsRead={markAllAsRead}
            onToggle={() => setIsDialogOpen(false)}
          >
            {children({
              onCloseFlyout: () => setIsDialogOpen(false),
            })}
          </NotificationsFlyoutBox>
        </DialogContent>
      </Dialog>
    </>
  );
}
